@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// トップページ共通スタイル
// ============================================================

.homeSection_title{
  margin-bottom: 1em;
  line-height: 1.1;
  text-align: center;
  font-size: 24px;
  font-style: italic;
  font-weight: bold;
  color: var.$text-color-white;
  letter-spacing: .02em;
}

.homeSection_hero{
  margin-bottom: 100px;
}


// ============================================================
// コンセプト - セクション固有
// ============================================================

.homeSection_concept{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  margin-bottom: 60px;
}

.conceptGroup_contentsUnit{
  line-height: var.$line-height-base;
  font-weight: bold;
  font-size: 16px;
}

// ============================================================
// ニュース - セクション固有
// ============================================================

.homeSection_news{
  margin-bottom: 80px;
}