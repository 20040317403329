@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.project_img{
  width: 100%;
  max-width: 100%;
  margin-bottom: 36px;

  img{
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}