@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

body{
  font-family: var.$font-family-base;
  color: var.$text-color;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  //
  background: var.$brand-main;
}

h1,h2,h3,h4,h5{
  font-size: var.$font-size-heading;
  line-height: var.$line-height-heading;
  color: var.$headings-color;
}

p{
  font-size: var.$font-size-base;
  line-height: var.$line-height-base;
  color: var.$text-color;
}