@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

@each $media, $blakepoint in var.$blakepoints {
  .hidden_#{$media}{
    @media screen and (max-width: #{$blakepoint}) {
      display: none!important;
    }
  }
}

@each $media, $blakepoint in var.$blakepoints {
  .visible_#{$media}{
    display: none!important;
    @media screen and (max-width: #{$blakepoint}) {
      display: block!important;
    }
  }
}

.dis_block{
  display: block;
}

.dis_inlineBlock{
  display: inline-block;
}

.dis_flex{
  display: flex;
}