@use "./variables" as var;
@use "./functions" as mix;


@mixin resetBtnStyle{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0; 
  appearance: none;
}

@mixin setTextOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin transitionDelay($val:var.$hover-transition) {
  transition: all $val ease;
}

@mixin setLinkHoverFadeAnime($val:var.$hover-transition){
  transition: all $val ease;
  &:hover{
    opacity: 0.7;
  }  
}

@mixin setTextLinkColor($color:#fff){
  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus{
    color: $color;
    text-decoration: none;
  }
}

@mixin clearfix{
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// @ media
// ------------------------------------------------------------

@mixin max-media($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-media($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin media($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}


// ============================================================
// プロジェクト固有ミックスイン
// ============================================================

@mixin setMainContentsWidth(){
  width: 1000px;
  max-width: 1000px;
  @media screen and (max-width: var.$screen-lg) {
    width: 100%;
    max-width: 100%;
  }
}