@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//supportページのバナーリスト
// ============================================================
.supporterBnrGroup{
  list-style: none;
  margin: 0 auto;
  padding: 4px 12px;

  &:first-child{
    padding-top: 24px;
  }

  &:last-child{
    padding-bottom: 0;
  }
}

//col1
// ============================================================
.supporterBnrGroup_col1{
  
  padding: 4px 64px;
  
  @include mix.max-media(var.$screen-sm){
    padding: 4px 24px;
  }
  
  .supporterBnrItem{
    margin-bottom: 24px;

  }
}


//col2
// ============================================================
.supporterBnrGroup_col2{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;

  .supporterBnrItem{
    width:calc((100% - 24px) / 2);
    margin-right: 24px;
    margin-bottom: 24px;
    text-align: center;

    &:nth-child(even){
      margin-right: 0;

    }

    a{
      display: block;

    }

    img{
      max-width: 100%;
      max-height: 80px;
    }
  }
}

.supporterBnrItem{
  a{
    transition: .3s;

    &:hover{
      transition: .3s;
      opacity: .7;
    }
  }

}

//レッスンページのバナーリスト
// ============================================================

.lessonBnrGroup{
  list-style: none;
}

.lessonBnrItem{
  border-bottom: 2px solid #ffffff;


  a{
    display: block;
    padding: 24px 32px;
    text-align: center;
    transition: .7s;

    &:hover{
      opacity: .7;
      transition: .7s;
    }
  }

  //TODO hanada レッスンバナーの数が増えたらコメントアウトを解除する必要があります

  //&:last-child{
  //  border-bottom: none;
  //  padding-bottom: 0;
  //}


}

//projectリスト
// ============================================================

.project_supportAboutList{
  list-style: none;
  margin-bottom: 24px;
}

.project_supportAboutItem{
  margin-bottom: 8px;
  font-family: var.$font-family-base;
  font-size: 10px;

  &:last-child{
    margin-bottom: 0;
  }
}