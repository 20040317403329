@use "../variables" as var;

@use "../mixins" as mix;
@use "../functions" as function;

.wrapper{
  width: 100%;
  max-width: var.$screen-md;
  margin: 0 auto;
  padding-top: var.$header_H;
  //&#support{
  //  background-color: rgb(98, 57, 54);
  //}
}

.container{
  margin: 0 auto;
}

.container_inner{
  width: 100%;
  max-width: var.$screen-md;
  height: auto;
  margin: 0 auto;
  padding: 0 var.$innerGapSp;
  @include mix.max-media(var.$screen-md){
    max-width: 100%;
  }
}

.container_inner02{
  width: 100%;
  max-width: 410px;
  height: auto;
  background-color: #231815;
  margin: 0 auto;
  padding: 0 var.$innerGapSp;

  @include mix.max-media(var.$screen-sm){
    max-width: 360px;
  }
}

//projectページ各記事固有コンテナ
// ============================================================

.project_container{
  margin-bottom: 160px;
}

//projectページ固有インナー
// ============================================================

.project_containerInner{
  padding: 0 15px 0 30px;

  @include mix.max-media(var.$screen-sm){
    padding: 0 45px;
  }
}