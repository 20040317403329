// ============================================================
//  @container
// ============================================================

$container-mlg : 1200px;
$container-pad : 50px;

// ============================================================
//  @Colors
// ============================================================

$brand-primary          :  #0082aa;
$brand-success          :  #137E00;
$brand-info             :  #4A90B9;
$brand-warning          :  #FF7F00;
$brand-error            :  #d9534f;
$brand-disable          :  #888888;

// brand color
// -------------------

$brand-main             : #231815;

$brand-main-lighter     :  $brand-main;
$brand-main-lighten     :  $brand-main;

$brand-main-darker      : #2c0851;
$brand-main-darken      :  $brand-main;

$brand-gray-lighten     :  #F7F7F7;
$brand-gray-darker      :  #616265;
$brand-gray-darken      :  #04122c;

// parts color
// -------------------

$background_layer_color : rgba(0, 0, 0, 0.7);


// text color
// -------------------
$text-color             :  #fff;
$text-color-sub         :  #ccc;
$text-color-placeholder :  #BCBCBC;
$text-color-disable     :  $brand-disable;
$text-color-white       :  #fff;
$border-color-base      :  #DEDEDE;

// link color
// -------------------
$link-color             :  #0079D1;
$link-visited-color     :  $link-color;
$link-active-color      :  $link-color;
$link-hover-color       :  $link-color;

// ============================================================
// @ タイポグラフィ設定
// ============================================================

// ベースフォント設定
// -------------------
$font-family-base        :  "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN",  "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
$font-size-base          :  16px;
$font-size-heading       :  $font-size-base;
$headings-color          :  #333;
$line-height-base        :  1.76;
$line-height-heading     :  1.4;

// ============================================================ //
// @ border-radius
// ============================================================ //

$border-radius-base      : 5px;

// ============================================================ //
// @ input関係
// ============================================================ //

$input-bg                 :  #fff;
$input-hover-bg           :  #f4f4f4;
$input-bg-disabled        :  $brand-gray-lighten;
$input-pad                :  .4em .6em .4em;
$input-pad-sm             :  .8em .6em;
$input-color              :  $text-color;
$input-border             :  #ccc;
$input-border-radius      :  $border-radius-base;
$input-active-color       :  $brand-primary;

// ============================================================ //
// @ transition & hover opacity 設定
// ============================================================ //

$hover-transition        :  .3s;
$hover-opacity           :  .7;

// ============================================================
// @ 全体構造定義
// ============================================================

$header_H               :86px;
$header-primary-menu_H  :60px;

// ============================================================ //
// @ Z-index定義
// ============================================================ //
$loading-layer            : 99999;

$zindex_modal             : 31000;
$zindex_modal_bg          : $zindex_modal - 1;

$zindex_offcanvas_trigger : 7999;
$zindex_offcanvas_menu    : $zindex_offcanvas_trigger - 1;
$zindex_offcanvas_bg      : $zindex_offcanvas_menu - 1;

$zindex-header            : 5000;

// ============================================================ //
// @ メディアクエリ設定
// ============================================================ //

// Extra small screen / phoneSE
$screen-xs               :  320px;
$screen-xs-min           :  $screen-xs;

// Small Mid Small screen / Iphone
$screen-sm               :  414px;
$screen-msm               :  375px;
$screen-msm-min           :  $screen-sm;

// Small screen / Iphone Plus

$screen-sm-min           :  $screen-sm;

// iphone5 land
$screen-5ls              :  568px;

// iphone6 land
$screen-6ls              :  667px;

// Medium screen
$screen-md               :  599px;
$screen-md               :  599px;
$screen-md-min           :  $screen-sm + 1;
$screen-desktop          :  $screen-md-min;

// Small large screen
$screen-slg               :  980px;
$screen-slg-min           :  $screen-md + 1;

// Mid large screen
$screen-mlg               :  1024px;
$screen-mlg-min           :  $screen-slg + 1;

// Large screen
$screen-lg               :   1366px;
$screen-lg-min           :   $screen-md + 1;

// Xlarge screen
$screen-xlg               :   1980px;
$screen-xlg-min           :   $screen-lg + 1;


$blakepoints: (
  'xlg' : $screen-xlg,
  'lg'  : $screen-lg,
  'mlg' : $screen-mlg,
  'slg' : $screen-slg,
  'md'  : $screen-md,
  '6ls' : $screen-6ls,
  '5ls' : $screen-5ls,
  'sm'  : $screen-sm,
  'msm' : $screen-msm,
  'xs'  : $screen-xs,
);

// グリッド関係
// ------------------------------------------------------------

$innerGapPc : 15px;
$innerGapSp : 15px;
$gridGapPc : 10px;
$gridGapSp : 10px;