@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.project_imgGrid{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
}

.project_imgItem{
  width: calc((100% - 10px) / 2);
  margin-right: 10px;
  margin-bottom: 10px;

  &:nth-child(even){
    margin-right: 0;
  }

  img{
    width: 100%;
    max-width: 100%;
  }
}
