@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;



$bliderBoxMaxH_PC : 250px;
$bliderBoxMaxH_SP : 250px;
$bliderBoxCoverH_PC : $bliderBoxMaxH_PC / 2;
$bliderBoxCoverH_SP : $bliderBoxMaxH_SP / 2;

$bliderBoxCoverColor : linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 50%);

.bliderBox{
  
  position: relative;
  max-height: $bliderBoxMaxH_PC;
  overflow: hidden;
  @include mix.transitionDelay();

  @include mix.max-media(var.$screen-msm){
    max-height: $bliderBoxMaxH_SP;
  }

  &:before{
    display: block;
    content: '';
    height: $bliderBoxCoverH_PC;
    background: $bliderBoxCoverColor;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include mix.max-media(var.$screen-msm){
      height: $bliderBoxCoverH_PC;
    }

  }

}

.bliderBox.js_isActive{
  max-height: 100%;
}


.bliderBox_triggerGroup{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;

}

.bliderBox_trigger{

}