@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// ============================================================
// 以下、Bootstrap Modalの構造スタイル上書きスタイル
// ============================================================

.modal {
  z-index: var.$zindex_modal;
}

.modal-backdrop.show{
  z-index: var.$zindex_modal_bg;
}

.modal-backdrop.show{
  background: var.$background_layer_color;
}


.modal-dialog{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
}

// ============================================================
// ここまで
// ============================================================

.modal_content{
  padding: 30px;
}