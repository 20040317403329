@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.newsHeadline{

}

.newsHeadlineItem{
  margin-bottom: 1.5em;

}

.newsHeadlineItem_date{

  border-bottom: solid 2px #fff;
  margin-bottom: 0.4em;
  padding-bottom: 0.4em;

  time{
    line-height: 1.1;
    font-size: 15px;
    font-weight: 300;
    font-style: italic;
    color: var.$text-color-white;
  }
}

.newsHeadline_title{
  span{
    display: inline-block;
    background: #fff;
    line-height: 1.4;
    font-size: 13px;
    color: var.$brand-main;
  }
}