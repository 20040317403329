@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.offcanvasBody{
  //
  position: fixed;
  z-index: var.$zindex_offcanvas_menu;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  transform : translate(-100%,0%);
  //
  background: #fff;
  overflow-y: scroll;
  //
  min-height: 100vh;
  padding: var.$header_H #{var.$innerGapSp * 2} 20px  #{var.$innerGapSp * 2};
  @include mix.transitionDelay();



  .js_isOffcanvasActive &{
    transform : translate(0%,0%);
  }
}

.offcanvasBody_inner{
  width: 100%;
  max-width: var.$screen-md;
  height: auto;
  margin: 0 auto;
  padding: 0 var.$innerGapSp;
  @include mix.max-media(var.$screen-md){
    max-width: 100%;
  }
}

// ============================================================
// オフキャンバスメニュー
// ============================================================

.offcanvasNav{
  padding: 20px 0;
}

.offcanvasNavBody{
  margin: 0;
  padding: 0;
  list-style: none;
}

.offcanvasNavItem{
  margin-bottom: 20px;
  > a{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    @include mix.setLinkHoverFadeAnime();
    &:active,
    &:link,
    &:visited,
    &:focus{
      color: #c9caca;
      text-decoration: none;
    }
  }
  &.is_current > a {
    &:active,
    &:link,
    &:visited,
    &:focus {
      color: var.$brand-main;
    }
  }
}

.offcanvasNavItem_en{
  padding-right: 0.5em;
  line-height: 1.1;
  font-size: 34px;
  font-style: italic;
  font-weight: bold;
}

.offcanvasNavItem_jp{
  line-height: 1.1;
  font-size: 11px;
  font-weight: bold;
}

// ============================================================
// オフキャンバス企業情報
// ============================================================

.offcanvasCompanyInfoHeader{
  border-bottom: solid 2px #727171;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.offcanvasCompanyInfoHeader_title,
.offcanvasCompanyInfoHeader_cName{
  line-height:1.6;
  font-size: 13px;
  font-weight: bold;
  color: #727171;
}

.offcanvasCompanyInfoItemVision{

}

.offcanvasCompanyInfoItemVision_title{
  line-height:1.6;
  font-size: 14px;
  font-weight: bold;
  color: #727171;
}

.offcanvasCompanyInfoItemVision_lead{
  line-height: 1.7;
  font-size: 12px;
  color: #727171;
}

.offcanvasCompanyInfoItemVision_textUnit{
  width: 70%;
  min-width: 200px;
  margin-top: 0.5em;

  >p {
    line-height: var.$line-height-base;
    font-size: 11px;
    color: #727171;
  }
}