@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.footer{
  width: 100%;
  height: 100px;
  &_inner{
    width: 100%;
    height: 100%;
    max-width: var.$screen-md;
    margin: 0 auto;
    padding: 0 var.$innerGapSp;
    @include mix.max-media(var.$screen-md){
      max-width: 100%;
    }
  }
}

.footerGroup{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.footerLogo{
  width: 100px;
  margin: 0 auto;
}

.footerCopy{
  text-align: center;
  line-height: 1;
  font-size: 12px;
  font-style: italic;

}