@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.textUnit{
  p{
    font-family: var.$font-family-base;
    font-size: 13px;
  }
}

.project_textUnit{
  margin-top: 16px;
}