@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//project_headingGroup
// ============================================================

.project_headingGroup{
  margin-bottom: 12px;
}

.project_headingDateTime{
  font-family: var.$font-family-base;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: .06em;
}

.project_heading{
  font-family: var.$font-family-base;
  font-style: italic;
  font-weight: bold;
  line-height: var.$line-height-heading;
  letter-spacing: .02em;
  font-size: 18px;
  color: #ffffff;
}

//support_headingGroup
// ============================================================

.support_headingGroup{
  margin-bottom: 12px;
}

.support_heading{
  font-family: var.$font-family-base;
  font-weight: bold;
  line-height: var.$line-height-heading;
  letter-spacing: .02em;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
}