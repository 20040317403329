@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.headerToggleBtn{
  position: relative;
  width: 50px;
  height:50px;
  cursor: pointer;
  background:transparent;
}

/*ボタン内側*/

.headerToggleBtn span{
  display: inline-block;
  transition: all .4s;/*アニメーションの設定*/
  position: absolute;
  left: 13px;
  height: 2px;
  background-color: #fff;

  .js_isOffcanvasActive &{
    background-color:var.$brand-main;
  }
}


.headerToggleBtn span:nth-of-type(1) {
  top:22px;
  width: 50%;
}

.headerToggleBtn span:nth-of-type(2) {
  top:29px;
  width:50%;
}

// アクティブ時
// -------------------

.js_isOffcanvasActive{
  .headerToggleBtn span:nth-of-type(1) {
    top: 20px;
    left: 16px;
    transform: translateY(6px) rotate(-45deg);
    width: 35%;
  }

  .headerToggleBtn span:nth-of-type(2) {
    top: 32px;
    left: 16px;
    transform: translateY(-6px) rotate(45deg);
    width: 35%;
  }
}
