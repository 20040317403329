@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.linkUnit{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  width: 100%;
  max-width: 100%;
  height: 110px;
  background: var.$text-color-white;
  margin-bottom: 60px;

  > a{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @include mix.setLinkHoverFadeAnime();
    //
    &:active,
    &:link,
    &:visited,
    &:focus{
      text-decoration: none;
      color: #fff;
    }
  }

}

.linkUnit_inner{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.linkUnit_titleEn{
  margin-bottom: 8px;
  line-height: 1.1;
  font-style: italic;
  font-size: 42px;
  font-weight: bold;
  color: var.$brand-main;
}

.linkUnit_titleJa{
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: var.$brand-main;
}

.linkUnit_label{
  position: absolute;
  right: var.$gridGapSp;
  bottom: var.$gridGapSp;
  color: var.$brand-main;
  font-size: 10px;
  font-weight: bold;
}

//固有要素

.homeSection_contact_linkUnit{
  height: 80px;
  margin-bottom: 40px;

  .linkUnit_titleEn{
    font-style: normal;
    font-size: 24px;
    margin-bottom: 0;

    @include mix.max-media(var.$screen-sm){
      font-size: 18px;
    }

  }
}