@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;



.text_bold{
  font-weight: bold;
}

.text_center{
  text-align: center;
}