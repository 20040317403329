@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.header{
  position: fixed;
  z-index: var.$zindex_offcanvas_trigger;
  top: 0;
  left: 0;
  right: 0;
  //
  width: 100%;
  height: var.$header_H;

  &_inner{
    width: 100%;
    height: 100%;
    max-width: var.$screen-md;
    margin: 0 auto;
    padding: 0 var.$innerGapSp;
    @include mix.max-media(var.$screen-md){
      max-width: 100%;
    }
  }
}

//
// ------------------------------------------------------------

.headerGroup{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.headerLogo{
  width: 100px;
  height: auto;
  
  .brandLogoFill{

    fill: #fff;

    .js_isOffcanvasActive &{
      fill: var.$brand-main;
    }

  }


  > a{
    display: block;
    @include mix.setLinkHoverFadeAnime();
  }

  > a img{
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
  }

}