@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.homeSection_hero{
  background: var.$brand-main;
}

.homeHero_title{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  //
  margin: 0 auto;
  line-height: 1.55;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 130px;
  font-weight: bold;
  color: var.$text-color-white;
  white-space: nowrap;

  @include mix.max-media(var.$screen-md){
    font-size: 25vw;
  }

  rt{
    font-size: 30px;
  }
}